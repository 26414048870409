
/* inter-300 */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('./inter/inter-300.woff2') format('woff2'),
    url('./inter/inter-300.woff') format('woff'), 
    url('./inter/inter-300.ttf') format('truetype');
}

/* inter-400 */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('./inter/inter-400.woff2') format('woff2'),
    url('./inter/inter-400.woff') format('woff'), 
    url('./inter/inter-400.ttf') format('truetype');
}

/* inter-500  */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('./inter/inter-500.woff2') format('woff2'),
    url('./inter/inter-500.woff') format('woff'), 
    url('./inter/inter-500.ttf') format('truetype');
}

/* inter-700  */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('./inter/inter-700.woff2') format('woff2'),
    url('./inter/inter-700.woff') format('woff'), 
    url('./inter/inter-700.ttf') format('truetype');
}
