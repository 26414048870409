
/* libre-franklin-300 */
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('./libre-franklin/libre-franklin-300.woff2') format('woff2'),
    url('./libre-franklin/libre-franklin-300.woff') format('woff'), 
    url('./libre-franklin/libre-franklin-300.ttf') format('truetype');
}

/* libre-franklin-400 */
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('./libre-franklin/libre-franklin-400.woff2') format('woff2'),
    url('./libre-franklin/libre-franklin-400.woff') format('woff'), 
    url('./libre-franklin/libre-franklin-400.ttf') format('truetype');
}

/* libre-franklin-500  */
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('./libre-franklin/libre-franklin-500.woff2') format('woff2'),
    url('./libre-franklin/libre-franklin-500.woff') format('woff'), 
    url('./libre-franklin/libre-franklin-500.ttf') format('truetype');
}

/* libre-franklin-700  */
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('./libre-franklin/libre-franklin-700.woff2') format('woff2'),
    url('./libre-franklin/libre-franklin-700.woff') format('woff'), 
    url('./libre-franklin/libre-franklin-700.ttf') format('truetype');
}
