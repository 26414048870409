/* craftSans-300 */
@font-face {
  font-family: 'Craft Sans';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('./craft-sans/CraftSans-Light.otf') format('opentype');
}

/* craftSans-400 */
@font-face {
  font-family: 'Craft Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('./craft-sans/CraftSans-Regular.otf') format('opentype');
}

/* craftSans-500 */
@font-face {
  font-family: 'Craft Sans';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('./craft-sans/CraftSans-Regular.otf') format('opentype');
}

/* craftSans-700 */
@font-face {
  font-family: 'Craft Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('./craft-sans/CraftSans-Regular.otf') format('opentype');
}