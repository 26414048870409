/* value-sans-300 */
@font-face {
  font-family: 'Value Sans';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('./value-sans/value-sans-regular-pro.ttf') format('truetype');
}

/* value-sans-400 */
@font-face {
  font-family: 'Value Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('./value-sans/value-sans-regular-pro.ttf') format('truetype');
}

/* value-sans-500 */
@font-face {
  font-family: 'Value Sans';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('./value-sans/value-sans-medium-italic-pro.ttf') format('truetype');
}

/* value-sans-700 */
@font-face {
  font-family: 'Value Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('./value-sans/value-sans-medium-italic-pro.ttf') format('truetype');
}