/* roboto-300 */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('./roboto/roboto-300.woff2') format('woff2'),
    url('./roboto/roboto-300.woff') format('woff'), 
    url('./roboto/roboto-300.ttf') format('truetype');
}

/* roboto-400 */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('./roboto/roboto-400.woff2') format('woff2'),
    url('./roboto/roboto-400.woff') format('woff'), 
    url('./roboto/roboto-400.ttf') format('truetype');
}

/* roboto-500 */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('./roboto/roboto-500.woff2') format('woff2'),
    url('./roboto/roboto-500.woff') format('woff'), 
    url('./roboto/roboto-500.ttf') format('truetype');
}

/* roboto-700 */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('./roboto/roboto-700.woff2') format('woff2'),
    url('./roboto/roboto-700.woff') format('woff'), 
    url('./roboto/roboto-700.ttf') format('truetype');
}