/* recife-display-300 */
@font-face {
  font-family: 'Recife Display';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('./recife-display/recife-display-regular.otf') format('opentype');
}

/* recife-display-400 */
@font-face {
  font-family: 'Recife Display';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('./recife-display/recife-display-regular.otf') format('opentype');
}

/* recife-display-500 */
@font-face {
  font-family: 'Recife Display';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('./recife-display/recife-display-medium-italic.otf') format('opentype');
}

/* recife-display-700 */
@font-face {
  font-family: 'Recife Display';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('./recife-display/recife-display-medium-italic.otf') format('opentype');
}